export default {
  translation: {
    common: {
      delete: 'Löschen',
      deleteModalTitle: 'Sind Sie sicher, dass Sie dieses Element löschen möchten?',
      ok: 'Ja',
      cancel: 'Nein',
      total: 'Gesamt',
      rename: 'Umbenennen',
      name: 'Name',
      save: 'Speichern',
      namePlaceholder: 'Bitte geben Sie einen Namen ein',
      next: 'Weiter',
      create: 'Erstellen',
      edit: 'Bearbeiten',
      upload: 'Hochladen',
      english: 'Englisch',
      chinese: 'Simplified Chinese',
      traditionalChinese: 'Traditional Chinese',
      language: 'Sprache',
      languageMessage: 'Bitte geben Sie Ihre Sprache ein!',
      languagePlaceholder: 'Wählen Sie Ihre Sprache',
      copy: 'Kopieren',
      copied: 'Kopiert',
      comingSoon: 'Kommend bald',
      download: 'Herunterladen',
      close: 'Schließen',
      preview: 'Vorschau',
      move: 'Verschieben',
      warn: 'Warnung',
      action: 'Aktion',
      s: 'S',
      pleaseSelect: 'Bitte wählen Sie',
      pleaseInput: 'Bitte geben Sie ein',
    },
    login: {
      login: 'Anmelden',
      signUp: 'Registrieren',
      loginDescription: 'Wir freuen uns, Sie wieder zu sehen!',
      registerDescription: 'Wir freuen uns, Sie auf unserer Plattform begrüßen zu dürfen!',
      emailLabel: 'E-Mail',
      emailPlaceholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
      passwordLabel: 'Passwort',
      passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
      rememberMe: 'Angemeldet bleiben',
      signInTip: 'Kein Konto?',
      signUpTip: 'Haben Sie bereits ein Konto?',
      nicknameLabel: 'Spitzname',
      nicknamePlaceholder: 'Bitte geben Sie Ihren Spitznamen ein',
      register: 'Konto erstellen',
      continue: 'Weiter',
      title: 'Erstellen Sie Ihre intelligenten Assistenten.',
      description:
        'Registrieren Sie sich kostenlos, um die Top-RAG-Technologie zu erkunden. Erstellen Sie Wissensbasen und AIs, um Ihr Unternehmen zu stärken.',
      review: 'aus 500+ Bewertungen',
    },
    header: {
      knowledgeBase: 'Wissensbasis',
      chat: 'Chat',
      register: 'Registrieren',
      signin: 'Anmelden',
      home: 'Startseite',
      setting: 'Benutzer-Einstellungen',
      logout: 'Abmelden',
      fileManager: 'Dateimanager',
      flow: 'Agent',
      search: 'Suche',
    },
    knowledgeList: {
      welcome: 'Willkommen zurück',
      description: 'Welche Wissensbasis möchten wir heute verwenden?',
      createKnowledgeBase: 'Wissensbasis erstellen',
      name: 'Name',
      namePlaceholder: 'Bitte geben Sie einen Namen ein!',
      doc: 'Dokumente',
      searchKnowledgePlaceholder: 'Suchen',
    },
    knowledgeDetails: {
      dataset: 'Datenmenge',
      testing: 'Abfrage-Test',
      files: 'Dateien',
      configuration: 'Konfiguration',
      name: 'Name',
      namePlaceholder: 'Bitte geben Sie einen Namen ein!',
      doc: 'Dokumente',
      datasetDescription:
        '😉 Fragen und Antworten können nur beantwortet werden, wenn die Analyse erfolgreich ist.',
      addFile: 'Datei hinzufügen',
      searchFiles: 'Dateien suchen',
      localFiles: 'Lokale Dateien',
      emptyFiles: 'Leere Datei erstellen',
      webCrawl: 'Web-Crawl',
      chunkNumber: 'Chunk-Nummer',
      uploadDate: 'Upload-Datum',
      chunkMethod: 'Chunk-Methode',
      enabled: 'Aktivieren',
      disabled: 'Deaktivieren',
      action: 'Aktion',
      parsingStatus: 'Analysestatus',
      processBeginAt: 'Prozessbeginn',
      processDuration: 'Prozessdauer',
      progressMsg: 'Fortschrittsmeldung',
      testingDescription:
        'Schritt 5! Nach erfolgreichem Abschluss, lassen Sie den Rest Infiniflow AI übernehmen.',
      similarityThreshold: 'Ähnlichkeits-Schwelle',
      similarityThresholdTip:
        'Wir verwenden einen hybriden Ähnlichkeitswert, um die Entfernung zwischen zwei Textzeilen zu bewerten. Es handelt sich um eine Gewichtete Ähnlichkeit und Vektorkosinusähnlichkeit. Wenn die Ähnlichkeit zwischen Abfrage und Chunk unter dieser Schwelle liegt, wird der Chunk ausgeschlossen.',
      vectorSimilarityWeight: 'Vektorkosinus-Weight',
      vectorSimilarityWeightTip:
        'Wir verwenden einen hybriden Ähnlichkeitswert, um die Entfernung zwischen zwei Textzeilen zu bewerten. Es handelt sich um eine Gewichtete Ähnlichkeit und Vektorkosinusähnlichkeit oder Rerank-Score (0~1). Die Summe beider Gewichte beträgt 1.0.',
      testText: 'Testtext',
      testTextPlaceholder: 'Bitte geben Sie Ihre Frage ein!',
      testingLabel: 'Testen',
      similarity: 'Hybride Ähnlichkeit',
      termSimilarity: 'Termin-Ähnlichkeit',
      vectorSimilarity: 'Vektorkosinus-Ähnlichkeit',
      hits: 'Treffer',
      view: 'Anzeigen',
      filesSelected: 'Ausgewählte Dateien',
      upload: 'Hochladen',
      run: 'Ausführen',
      runningStatus0: 'NICHT STARTET',
      runningStatus1: 'Analyse',
      runningStatus2: 'ABGELEHNT',
      runningStatus3: 'ERFOLGREICH',
      runningStatus4: 'FEHLER',
      pageRanges: 'Seitennummern',
      pageRangesTip:
        'Seitennummern: Definieren Sie die Seitennummern, die analysiert werden sollen. Die nicht in diesen Nummern enthaltenen Seiten werden ignoriert.',
      fromPlaceholder: 'Von',
      fromMessage: 'Fehlende Startseitennummer',
      toPlaceholder: 'Bis',
      toMessage: 'Fehlende Endseitennummer (exklusive)',
      layoutRecognize: 'Layout-Erkennung',
      layoutRecognizeTip:
        'Verwenden Sie visuelle Modelle zur Layoutanalyse, um die Dokumentstruktur besser zu identifizieren, um Titel, Textblöcke, Bilder und Tabellen zu finden. Ohne diese Funktion können nur der Text des PDFs abgerufen werden.',
      taskPageSize: 'Aufgaben-Seitengröße',
      taskPageSizeMessage: 'Bitte geben Sie die Aufgaben-Seitengröße ein!',
      taskPageSizeTip: `Wenn Sie Layout-Erkennung verwenden, wird das PDF in Gruppen aufgeteilt. Die Layoutanalyse wird parallel zwischen den Gruppen durchgeführt, um die Verarbeitungsgeschwindigkeit zu erhöhen. Die 'Aufgaben-Seitengröße' bestimmt die Größe der Gruppen. Je größer die Seitenzahl ist, desto geringer ist die Wahrscheinlichkeit, dass kontinuierlicher Text zwischen den Seiten in verschiedene Chunk aufgeteilt wird.`,
      addPage: 'Seite hinzufügen',
      greaterThan: 'Die aktuelle Zahl muss größer als sein!',
      greaterThanPrevious:
        'Die aktuelle Zahl muss größer als die vorherige sein!',
      selectFiles: 'Dateien auswählen',
      changeSpecificCategory: 'Spezifische Kategorie ändern',
      uploadTitle: 'Klicken oder Datei hierhin ziehen, um hochzuladen',
      uploadDescription:
        'Unterstützt Einzel- oder Massenupload. Der Upload von Unternehmensdaten oder anderen gesperrten Dateien ist strengstens verboten.',
      chunk: 'Chunk',
      bulk: 'Bulk',
      cancel: 'Abbrechen',
      rerankModel: 'Rerank-Modell',
      rerankPlaceholder: 'Bitte wählen Sie',
      rerankTip: `Wenn leer. Es verwendet Embeddings von Abfrage und Chunk, um Vektorkosinusähnlichkeit zu berechnen. Ansonsten verwendet es Rerank-Score anstelle von Vektorkosinusähnlichkeit.`,
      topK: 'Top-K',
      topKTip: `K Chunk werden an Rerank-Modeln weitergegeben.`,
      delimiter: `Trennzeichen`,
      html4excel: 'Excel in HTML',
      html4excelTip: `Excel wird in HTML-Tabelle oder nicht umgewandelt. Wenn es FALSE ist, wird jede Zeile in Excel als Chunk behandelt.`,
      autoKeywords: 'Auto-Schlüsselwörter',
      autoKeywordsTip: `Ziehen Sie N Schlüsselwörter für jeden Chunk, um ihren Rang-Score zu erhöhen, während Sie solche Schlüsselwörter abfragen. Zusätzliche Token werden für LLM konsumiert, die Sie in 'System-Modell-Einstellungen' festgelegt haben. Sie können das Ergebnis im Chunk-Liste überprüfen.`,
      autoQuestions: 'Auto-Fragen',
      autoQuestionsTip: `Ziehen Sie N Fragen für jeden Chunk, um ihren Rang-Score zu erhöhen, während Sie solche Fragen abfragen. Zusätzliche Token werden für LLM konsumiert, die Sie in 'System-Modell-Einstellungen' festgelegt haben. Diese Funktion zerstört nicht die gesamte Chunk-Verarbeitung, außer dass sie leere Ergebnisse zur ursprünglichen Chunk hinzufügt.`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'Aktualisieren Sie die Details Ihrer Wissensbasis, insbesondere die Analysemethode hier.',
      name: 'Wissensbasisname',
      photo: 'Wissensbasisfoto',
      description: 'Beschreibung',
      language: 'Sprache',
      languageMessage: 'Bitte geben Sie Ihre Sprache ein!',
      languagePlaceholder: 'Bitte geben Sie Ihre Sprache ein!',
      permissions: 'Berechtigungen',
      embeddingModel: 'Embedding-Modell',
      chunkTokenNumber: 'Chunk-Token-Zahl',
      chunkTokenNumberMessage: 'Chunk-Token-Zahl ist erforderlich',
      embeddingModelTip:
        'Das Embedding-Modell, das verwendet wird, um Chunk zu embedden. Es kann nicht geändert werden, wenn die Wissensbasis Chunk enthält. Sie müssen alle Chunk löschen, wenn Sie es ändern möchten.',
      permissionsTip:
        'Wenn die Berechtigung "Team" ist, können alle Teammitglieder die Wissensbasis manipulieren.',
      chunkTokenNumberTip:
        'Bestimmt die Token-Zahl eines Chunk ungefähr.',
      chunkMethod: 'Chunk-Methode',
      chunkMethodTip: 'Die Anleitung ist rechts.',
      upload: 'Hochladen',
      english: 'Englisch',
      chinese: 'Chinesisch',
      embeddingModelPlaceholder: 'Bitte wählen Sie ein Embedding-Modell',
      chunkMethodPlaceholder: 'Bitte wählen Sie eine Chunk-Methode',
      save: 'Speichern',
      me: 'Nur ich',
      team: 'Team',
      cancel: 'Abbrechen',
      methodTitle: 'Chunking-Methode-Beschreibung',
      methodExamples: 'Beispiele',
      methodExamplesDescription:
        'Die folgenden Screenshots sollen die Verständlichkeit erleichtern.',
      dialogueExamplesTitle: 'Dialog-Beispiele',
      methodEmpty:
        'Dies wird eine visuelle Erklärung der Wissensbasis-Kategorien anzeigen.',
      book: `<p>Unterstützte Dateiformate sind <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Da ein Buch lang ist und nicht alle Teile nützlich sind, wenn es sich um ein PDF handelt, sollten Sie die <i>Seitennummern</i> für jedes Buch einrichten, um negative Auswirkungen zu vermeiden und die Rechenzeit für die Analyse zu sparen.</p>`,
      laws: `<p>Unterstützte Dateiformate sind <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Rechtstexte haben eine sehr strenge Schreibweise. Wir verwenden Textmerkmale, um den Split-Punkt zu erkennen.</p><p>
      Die Chunk-Granularität ist konsistent mit 'ARTICLE', und alle übergeordneten Texte werden in den Chunk aufgenommen.</p>`,
      manual: `<p>Nur <b>PDF</b> wird unterstützt.</p><p>
      Wir nehmen an, dass das Manuskript eine hierarchische Abschnittsstruktur hat. Wir verwenden die niedrigsten Abschnittstitel als Scheitelpunkte, um Dokumente zu schneiden.
      So werden die Bilder und Tabellen in derselben Abschnitt nicht getrennt, und die Chunk-Größe kann groß sein.</p>`,
      naive: `<p>Unterstützte Dateiformate sind <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
      <p>Dieser Methode werden naive Wege zur Chunk-Verarbeitung angewendet:</p>
      <p>
      <li>Successive Text wird in Stücke geschnitten, die mit visueller Erkennungsmethode erkannt werden.</li>
      <li>Als nächstes werden diese Stücke zu Chunken zusammengefügt, deren Tokenzahl nicht mehr als 'Tokenzahl' beträgt.</li></p>`,
      paper: `<p>Nur <b>PDF</b>-Dateien werden unterstützt.</p><p>
      Wenn unser Modell gut funktioniert, werden die Artikel in Abschnitte geschnitten, wie <i>Abstract, 1.1, 1.2</i>, usw.</p><p>
      Der Vorteil besteht darin, dass LLM die Inhalte der relevanten Abschnitte besser zusammenfassen kann, was zu umfassenderen Antworten führt, die den Lesern helfen, den Artikel besser zu verstehen.
      Der Nachteil besteht darin, dass dies die Kontexte des LLM-Gesprächs erhöht und die Rechenzeit erhöht, sodass Sie während des Gesprächs die 'TopN'-Einstellung reduzieren können.</p>`,
      presentation: `<p>Unterstützte Dateiformate sind <b>PDF</b>, <b>PPTX</b>.</p><p>
      Jede Seite wird als Chunk behandelt. Und die Thumbnail jeder Seite wird gespeichert.</p><p>
      <i>Alle PPT-Dateien, die Sie hochgeladen haben, werden automatisch mit dieser Methode chunkt, und die Einstellung für jede PPT-Datei ist nicht erforderlich.</i></p>`,
      qa: `
      <p>
      Diese Chunk-Methode unterstützt <b>EXCEL</b> und <b>CSV/TXT</b>-Dateiformate.
    </p>
    <li>
      Wenn die Datei in <b>Excel</b>-Format ist, sollte sie zwei Spalten enthalten
      ohne Überschriften: eine für Fragen und eine für Antworten, wobei die Frage-Spalte der Antwort-Spalte vorausgeht. Mehrere Blätter sind akzeptabel, solange die Spalten korrekt strukturiert sind.
    </li>
    <li>
      Wenn die Datei in <b>CSV/TXT</b>-Format ist, muss sie UTF-8-gekodiert sein und TAB als Trennzeichen verwenden, um Fragen und Antworten zu trennen.
    </li>
    <p>
      <i>
        Zeilen, die nicht den oben genannten Regeln folgen, werden ignoriert, und jede Q&A-Paar wird als ein einzelner Chunk betrachtet.
      </i>
    </p>
      `,
      resume: `<p>Unterstützte Dateiformate sind <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Der Lebenslauf kommt in verschiedenen Formaten vor, genauso wie eine Person eine Persönlichkeit hat, aber wir müssen sie in strukturierte Daten umwandeln, die es leichter macht, sie zu durchsuchen.
      </p><p>
      Anstatt den Lebenslauf zu chunken, analysieren wir den Lebenslauf in strukturierte Daten. Als HR können Sie alle Lebensläufe, die Sie haben, abladen, 
      und dann können Sie alle Kandidaten auflisten, die die Anforderungen erfüllen, indem Sie mit <i>'RAGFlow'</i> sprechen.</p>
      `,
      table: `<p><b>EXCEL</b> und <b>CSV/TXT</b>-Dateiformate werden unterstützt.</p><p>
      Hier sind einige Tipps:
      <ul>
    <li>Bei csv oder txt-Dateien ist der Trennzeichen zwischen Spalten <em><b>TAB</b></em>.</li>
    <li>Die erste Zeile muss Überschriften enthalten.</li>
    <li>Die Überschriften müssen sinnvolle Begriffe sein, damit unser LLM sie versteht.
    Es ist gut, einige Synonyme mit Schrägstrichen <i>'/'</i> zu separieren, und noch besser, Werte mit eckigen Klammern wie <i>'gender/sex(male, female)'</i> zu separieren.</p>
    Hier sind einige Beispiele für Überschriften:<ol>
        <li>lieferant/lieferant<b>'TAB'</b>farbe(yellow, red, brown)<b>'TAB'</b>gender/sex(male, female)<b>'TAB'</b>größe(M,L,XL,XXL)</li>
        <li>name/namen<b>'TAB'</b>telefon/telefon/WeChat<b>'TAB'</b>höchste Ausbildung (Hochschule, Berufsschule, Master, Bachelor, Grundschule, Mittlere Berufsausbildung, Fachschule, Fachabitur, MPA, MBA, EMBA)</li>
        </ol>
        </p>
    </li>
    <li>Jede Zeile in der Tabelle wird als Chunk behandelt.</li>
    </ul>`,
      picture: `
    <p>Bilddateien werden unterstützt. Video kommt bald.</p><p>
    Wenn das Bild Text enthält, wird OCR verwendet, um den Text als Beschreibung des Bildes zu extrahieren.
    </p><p>
    Wenn der von OCR extrahierte Text nicht ausreicht, wird ein visuelles LLM verwendet, um die Beschreibung zu erhalten.
    </p>`,
      one: `
    <p>Unterstützte Dateiformate sind <b>DOCX, EXCEL, PDF, TXT</b>.</p><p>
    Für ein Dokument wird es als ein einzelner Chunk behandelt, ohne dass es geteilt wird.
    </p><p>
    Wenn Sie etwas zusammenfassen möchten, das den gesamten Kontext eines Artikels benötigt und die vom LLM verwendete Kontextlänge die Dokumentlänge abdeckt, können Sie diese Methode ausprobieren.
    </p>`,
      knowledgeGraph: `<p>Unterstützte Dateiformate sind <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>

<p>Nachdem die Dateien chunkt wurden, wird es verwendet, um aus den Chunken ein Wissensgraph und eine Mindmap des gesamten Dokuments zu extrahieren. Diese Methode verwendet naive Wege, um Dateien zu chunken:
Successive Text wird in Stücke geschnitten, die jeweils etwa 512 Token umfassen.</p>
<p>Als nächstes werden die Chunke an LLM übergeben, um Knoten und Beziehungen eines Wissensgraphen und einer Mindmap zu extrahieren.</p>

Achten Sie darauf, dass Sie die entitätstypen angeben müssen.</p>`,
      useRaptor: 'Raptor verwenden, um die Abfrage zu verbessern',
      useRaptorTip:
        'Recursive Abstractive Processing for Tree-Organized Retrieval, siehe https://huggingface.co/papers/2401.18059',
      prompt: 'Prompt',
      promptTip: 'LLM-Prompt für Zusammenfassung.',
      promptMessage: 'Prompt ist erforderlich',
      promptText: `Zusammenfassen Sie bitte die folgenden Absätze. Seien Sie vorsichtig mit Zahlen, machen Sie nichts auf, was nicht stimmt. Absätze wie folgt:
      {cluster_content}
Die oben stehende ist der Inhalt, den Sie zusammenfassen müssen.`,
      maxToken: 'Maximal-Token',
      maxTokenTip: 'Maximal-Tokens für Zusammenfassung.',
      maxTokenMessage: 'Maximal-Token ist erforderlich',
      threshold: 'Schwellenwert',
      thresholdTip: 'Je größer der Schwellenwert ist, desto weniger Cluster werden ausgegeben.',
      thresholdMessage: 'Schwellenwert ist erforderlich',
      maxCluster: 'Maximal-Cluster',
      maxClusterTip: 'Maximal-Clusterzahl.',
      maxClusterMessage: 'Maximal-Clusterzahl ist erforderlich',
      randomSeed: 'Zufallskeimzahl',
      randomSeedMessage: 'Zufallskeimzahl ist erforderlich',
      entityTypes: 'Entitätstypen',
    },
    chunk: {
      chunk: 'Chunk',
      bulk: 'Bulk',
      selectAll: 'Alle auswählen',
      enabledSelected: 'Aktivieren ausgewählt',
      disabledSelected: 'Deaktivieren ausgewählt',
      deleteSelected: 'Ausgewählte löschen',
      search: 'Suchen',
      all: 'Alle',
      enabled: 'Aktiviert',
      disabled: 'Deaktiviert',
      keyword: 'Schlüsselwort',
      function: 'Funktion',
      chunkMessage: 'Bitte geben Sie einen Wert ein!',
      full: 'Volltext',
      ellipse: 'Ellipse',
      graph: 'Wissensgraph',
      mind: 'Mindmap',
    },
    chat: {
      newConversation: 'Neue Konversation',
      createAssistant: 'Assistent erstellen',
      assistantSetting: 'Assistenteneinstellungen',
      promptEngine: 'Prompt-Engine',
      modelSetting: 'Modell-Einstellungen',
      chat: 'Chat',
      newChat: 'Neuer Chat',
      send: 'Senden',
      sendPlaceholder: 'Nachricht an den Assistenten schreiben...',
      chatConfiguration: 'Chat-Konfiguration',
      chatConfigurationDescription:
        'ier können Sie einen Assistenten für Ihre spezielle Wissensbasis anpassen! ',
      assistantName: 'Assistentenname',
      assistantNameMessage: 'Assistentenname ist erforderlich',
      namePlaceholder: 'z.B. Lebenslauf-Jarvis',
      assistantAvatar: 'Assistentenbild',
      language: 'Sprache',
      emptyResponse: 'Leere Antwort',
      emptyResponseTip: `Wenn nichts mit der Benutzerfrage abgerufen wird, wird diese als Antwort verwendet. Wenn Sie möchten, dass LLM eigene Meinungen äußert, wenn nichts abgerufen wird, lassen Sie dies leer.`,
      setAnOpener: 'Öffner setzen',
      setAnOpenerInitial: `Hallo! Ich bin Ihr Assistent, was kann ich für Sie tun?`,
      setAnOpenerTip: 'Wie möchten Sie Ihre Kunden begrüßen?',
      knowledgeBases: 'Wissensbasen',
      knowledgeBasesMessage: 'Bitte wählen Sie',
      knowledgeBasesTip: 'Wählen Sie die mit der Konversation verbundenen Wissensbasen.',
      system: 'System',
      systemInitialValue: `Sie sind ein intelligentes Assistenten. Bitte zusammenfassen Sie den Inhalt der Wissensbasis, um die Frage zu beantworten. Bitte listen Sie die Daten in der Wissensbasis auf und beantworten Sie detailliert. Wenn alle Wissensbasis-Inhalte irrelevant zur Frage sind, muss Ihre Antwort die Zeile "Die Antwort, die Sie suchen, ist nicht in der Wissensbasis gefunden!" enthalten. Die Antworten müssen die Chat-Geschichte berücksichtigen.
      Hier ist die Wissensbasis:
      {knowledge}
Die oben stehende ist die Wissensbasis.`,
      systemMessage: 'Bitte geben Sie ein!',
      systemTip:
        'Anweisungen, die LLM befolgen soll, wenn LLM Fragen beantwortet, wie z.B. Charakterdesign, Antwortlänge und Antwortsprache usw.',
      topN: 'Top-N',
      topNTip: `Nicht alle Chunke, deren Ähnlichkeitswert größer als die 'Ähnlichkeits-Schwelle' ist, werden an LLM weitergegeben. LLM kann nur diese 'Top-N' Chunke sehen.`,
      variable: 'Variable',
      variableTip: `Wenn Sie Dialog-APIs verwenden, können die Variablen Ihnen helfen, Ihre Kunden mit verschiedenen Strategien zu unterhalten. 
      Die Variablen werden verwendet, um den 'System'-Teil in dem Prompt zu füllen, um LLM einen Hinweis zu geben.
      Die 'knowledge' ist eine sehr spezielle Variable, die mit den abgerufenen Chunken gefüllt wird.
      Alle Variablen in 'System' sollten in Klammern geschrieben sein.`,
      add: 'Hinzufügen',
      key: 'Schlüssel',
      optional: 'Optional',
      operation: 'Operation',
      model: 'Modell',
      modelTip: 'Große Sprachmodell',
      modelMessage: 'Bitte wählen Sie!',
      freedom: 'Freiheit',
      improvise: 'Improvisieren',
      precise: 'Genau',
      balance: 'Balance',
      freedomTip: '"Genau" bedeutet, dass LLM vorsichtig und bedacht antwortet. "Improvisieren" bedeutet, dass Sie möchten, dass LLM viel und frei spricht. "Balance" ist zwischen vorsichtig und frei.',
      temperature: 'Temperatur',
      temperatureMessage: 'Temperatur ist erforderlich',
      temperatureTip:
        'Dieses Parameter bestimmt die Zufälligkeit der Vorhersagen durch das Modell. Eine niedrigere Temperatur macht das Modell sicherer in seinen Antworten, während eine höhere Temperatur es kreativer und vielfältiger macht.',
      topP: 'Top-P',
      topPMessage: 'Top-P ist erforderlich',
      topPTip:
        'Auch bekannt als "Nukleus-Stichprobenziehung", dieses Parameter setzt einen Schwellenwert, um eine kleinere Menge an Wörtern auszuwählen. Es konzentriert sich auf die wahrscheinlichsten Wörter, indem es die weniger wahrscheinlichen ausschließt.',
      presencePenalty: 'Anwesenheitsstrafe',
      presencePenaltyMessage: 'Anwesenheitsstrafe ist erforderlich',
      presencePenaltyTip:
        'Dies diskriminiert das Modell, indem es Wörter bestraft, die bereits in der Konversation vorkommen.',
      frequencyPenalty: 'Häufigkeitsstrafe',
      frequencyPenaltyMessage: 'Häufigkeitsstrafe ist erforderlich',
      frequencyPenaltyTip:
        'Ähnlich der Anwesenheitsstrafe reduziert dies die Neigung des Modells, dasselbe zu wiederholen.',
      maxTokens: 'Maximale Tokens',
      maxTokensMessage: 'Maximale Tokens sind erforderlich',
      maxTokensTip:
        'Dies setzt die maximale Länge des Modell-Ausgabe, gemessen in der Anzahl der Tokens (Wörter oder Teile von Wörtern).',
      quote: 'Zitat anzeigen',
      quoteTip: 'Sollte die Quelle des ursprünglichen Textes angezeigt werden?',
      selfRag: 'Selbst-RAG',
      selfRagTip: 'Bitte referenzieren Sie: https://huggingface.co/papers/2310.11511',
      overview: 'Chat-ID',
      pv: 'Anzahl der Nachrichten',
      uv: 'Aktive Benutzerzahl',
      speed: 'Token-Ausgabe-Geschwindigkeit',
      tokens: 'Verbrauchte Tokenzahl',
      round: 'Sitzung-Interaktion-Zahl',
      thumbUp: 'Kundenzufriedenheit',
      preview: 'Vorschau',
      embedded: 'Eingebettet',
      serviceApiEndpoint: 'Dienst-Api-Endpunkt',
      apiKey: 'Api-Schlüssel',
      apiReference: 'Api-Dokumentation',
      dateRange: 'Datumsspanne:',
      backendServiceApi: 'Api-Server',
      createNewKey: 'Neuen Schlüssel erstellen',
      created: 'Erstellt',
      action: 'Aktion',
      embedModalTitle: 'In Website einbetten',
      comingSoon: 'Kommend bald',
      fullScreenTitle: 'Vollbildschirm',
      fullScreenDescription:
        'Einbetten Sie die folgende Iframe in Ihre Website an der gewünschten Stelle',
      partialTitle: 'Teilweise einbetten',
      extensionTitle: 'Chrome-Erweiterung',
      tokenError: 'Bitte erstellen Sie einen Api-Schlüssel!',
      searching: 'Suchen...',
      parsing: 'Analyse',
      uploading: 'Hochladen',
      uploadFailed: 'Hochladen fehlgeschlagen',
      regenerate: 'Regenerieren',
      read: 'Inhalt lesen',
      tts: 'Text-zu-Sprache',
      ttsTip:
        'Um die Stimme mit Sprachumwandlung zu spielen, wählen Sie bitte TTS (Sprachumwandlung-Modell) in den Einstellungen aus.',
      relatedQuestion: 'Verwandte Frage',
      answerTitle: 'R',
      multiTurn: 'Multi-Turn-Optimierung',
      multiTurnTip:
        'In Multi-Runden-Konversationen wird die Abfrage nach der Wissensbasis optimiert. Das große Modell wird aufgerufen, um zusätzliche Tokens zu konsumieren.',
      howUseId: 'Wie verwendet man die Chat-ID?',
    },
    setting: {
      profile: 'Profil',
      profileDescription: 'Aktualisieren Sie Ihr Foto und Ihre persönlichen Details hier.',
      password: 'Passwort',
      passwordDescription:
        'Bitte geben Sie Ihr aktuelles Passwort ein, um Ihr Passwort zu ändern.',
      model: 'Modell-Anbieter',
      modelDescription: 'Setzen Sie die Modellparameter und den Api-Schlüssel hier.',
      team: 'Team',
      system: 'System',
      logout: 'Abmelden',
      api: 'Api',
      username: 'Benutzername',
      usernameMessage: 'Bitte geben Sie Ihren Benutzernamen ein!',
      photo: 'Ihr Foto',
      photoDescription: 'Dies wird auf Ihrem Profil angezeigt.',
      colorSchema: 'Farbschema',
      colorSchemaMessage: 'Bitte wählen Sie Ihr Farbschema!',
      colorSchemaPlaceholder: 'Wählen Sie Ihr Farbschema',
      bright: 'Hell',
      dark: 'Dunkel',
      timezone: 'Zeitzone',
      timezoneMessage: 'Bitte geben Sie Ihre Zeitzone ein!',
      timezonePlaceholder: 'Wählen Sie Ihre Zeitzone',
      email: 'E-Mail-Adresse',
      emailDescription: 'Nach der Registrierung kann die E-Mail-Adresse nicht geändert werden.',
      currentPassword: 'Aktuelles Passwort',
      currentPasswordMessage: 'Bitte geben Sie Ihr Passwort ein!',
      newPassword: 'Neues Passwort',
      newPasswordMessage: 'Bitte geben Sie Ihr Passwort ein!',
      newPasswordDescription:
        'Ihr neues Passwort muss mindestens 8 Zeichen lang sein.',
      confirmPassword: 'Bestätigen Sie Ihr neues Passwort',
      confirmPasswordMessage: 'Bitte bestätigen Sie Ihr Passwort!',
      confirmPasswordNonMatchMessage:
        'Das neue Passwort, das Sie eingegeben haben, stimmt nicht überein!',
      cancel: 'Abbrechen',
      addedModels: 'Hinzugefügte Modelle',
      modelsToBeAdded: 'Modelle, die hinzugefügt werden sollen',
      addTheModel: 'Das Modell hinzufügen',
      apiKey: 'Api-Schlüssel',
      apiKeyMessage:
        'Bitte geben Sie den Api-Schlüssel ein (für lokal installierte Modelle ignorieren Sie dies).',
      apiKeyTip:
        'Der Api-Schlüssel kann durch Registrierung des entsprechenden LLM-Anbieters erhalten werden.',
      showMoreModels: 'Weitere Modelle anzeigen',
      baseUrl: 'Base-Url',
      baseUrlTip:
        'Wenn Ihr Api-Schlüssel von OpenAI stammt, ignorieren Sie ihn. Jeder andere Zwischenanbieter gibt diese Base-Url mit dem Api-Schlüssel.',
      modify: 'Ändern',
      systemModelSettings: 'System-Modell-Einstellungen',
      chatModel: 'Chat-Modell',
      chatModelTip:
        'Das Standard-Chat-Modell, das alle neu erstellten Wissensbasen verwenden.',
      embeddingModel: 'Embedding-Modell',
      embeddingModelTip:
        'Das Standard-Embedding-Modell, das alle neu erstellten Wissensbasen verwenden.',
      img2txtModel: 'Img2txt-Modell',
      img2txtModelTip:
        'Das Standard-Multi-Modell, das alle neu erstellten Wissensbasen verwenden. Es kann ein Bild oder Video beschreiben.',
      sequence2txtModel: 'Sequence2txt-Modell',
      sequence2txtModelTip:
        'Das Standard-ASR-Modell, das alle neu erstellten Wissensbasen verwenden. Verwenden Sie dieses Modell, um Stimmen in entsprechenden Text umzuwandeln.',
      rerankModel: 'Rerank-Modell',
      rerankModelTip: 'Das Standard-Rerank-Modell, das verwendet wird, um Chunke zu reranken, die durch Benutzerfragen abgerufen werden.',
      ttsModel: 'TTS-Modell',
      ttsModelTip:
        'Das Standard-TTS-Modell, das verwendet wird, um Sprache während der Konversation zu generieren, wenn angefordert.',
      workspace: 'Arbeitsplatz',
      upgrade: 'Upgrade',
      addLlmTitle: 'LLM hinzufügen',
      modelName: 'Modellname',
      modelID: 'Modell-ID',
      modelUid: 'Modell-UID',
      modelNameMessage: 'Bitte geben Sie Ihren Modellnamen ein!',
      modelType: 'Modelltyp',
      modelTypeMessage: 'Bitte geben Sie Ihren Modelltyp ein!',
      addLlmBaseUrl: 'Base-Url',
      baseUrlNameMessage: 'Bitte geben Sie Ihre Base-Url ein!',
      vision: 'Unterstützt Vision?',
      ollamaLink: 'Wie man {{name}} integriert',
      FishAudioLink: 'Wie man FishAudio verwendet',
      TencentCloudLink: 'Wie man TencentCloud ASR verwendet',
      volcModelNameMessage: 'Bitte geben Sie Ihren Modellnamen ein!',
      addEndpointID: 'Endpoint-ID des Modells',
      endpointIDMessage: 'Bitte geben Sie Ihre Endpoint-ID des Modells ein',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Bitte geben Sie Ihren ARK_API_KEY ein',
      bedrockModelNameMessage: 'Bitte geben Sie Ihren Modellnamen ein!',
      addBedrockEngineAK: 'Zugriffschlüssel',
      bedrockAKMessage: 'Bitte geben Sie Ihren Zugriffschlüssel ein',
      addBedrockSK: 'Geheimer Schlüssel',
      bedrockSKMessage: 'Bitte geben Sie Ihren Geheimen Schlüssel ein',
      bedrockRegion: 'AWS-Region',
      bedrockRegionMessage: 'Bitte wählen Sie!',
      'us-east-1': 'US Ost (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asien-Pazifik (Singapur)',
      'ap-northeast-1': 'Asien-Pazifik (Tokyo)',
      'eu-central-1': 'Europa (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asien-Pazifik (Sydney)',
      addHunyuanSID: 'Hunyuan-Secret-ID',
      HunyuanSIDMessage: 'Bitte geben Sie Ihre Secret-ID ein',
      addHunyuanSK: 'Hunyuan-Secret-Schlüssel',
      HunyuanSKMessage: 'Bitte geben Sie Ihren Secret-Schlüssel ein',
      addTencentCloudSID: 'TencentCloud-Secret-ID',
      TencentCloudSIDMessage: 'Bitte geben Sie Ihre Secret-ID ein',
      addTencentCloudSK: 'TencentCloud-Secret-Schlüssel',
      TencentCloudSKMessage: 'Bitte geben Sie Ihren Secret-Schlüssel ein',
      SparkModelNameMessage: 'Bitte wählen Sie Spark-Modell',
      addSparkAPIPassword: 'Spark-API-Passwort',
      SparkAPIPasswordMessage: 'Bitte geben Sie Ihr API-Passwort ein',
      addSparkAPPID: 'Spark-APP-ID',
      SparkAPPIDMessage: 'Bitte geben Sie Ihre APP-ID ein',
      addSparkAPISecret: 'Spark-APISchlüssel',
      SparkAPISecretMessage: 'Bitte geben Sie Ihren APISchlüssel ein',
      addSparkAPIKey: 'Spark-API-Schlüssel',
      SparkAPIKeyMessage: 'Bitte geben Sie Ihren API-Schlüssel ein',
      yiyanModelNameMessage: 'Bitte geben Sie Ihren Modellnamen ein',
      addyiyanAK: 'Yiyan-API-Schlüssel',
      yiyanAKMessage: 'Bitte geben Sie Ihren API-Schlüssel ein',
      addyiyanSK: 'Yiyan-Geheimer Schlüssel',
      yiyanSKMessage: 'Bitte geben Sie Ihren Geheimen Schlüssel ein',
      FishAudioModelNameMessage:
        'Bitte geben Sie Ihrem Sprachsynthesemodell einen Namen',
      addFishAudioAK: 'Fish Audio-API-Schlüssel',
      addFishAudioAKMessage: 'Bitte geben Sie Ihren API-Schlüssel ein',
      addFishAudioRefID: 'FishAudio-Referenz-ID',
      addFishAudioRefIDMessage:
        'Bitte geben Sie die Referenz-ID ein (lassen Sie es leer, um das Standardmodell zu verwenden).',
      GoogleModelIDMessage: 'Bitte geben Sie Ihren Modell-ID ein!',
      addGoogleProjectID: 'Projekt-ID',
      GoogleProjectIDMessage: 'Bitte geben Sie Ihre Projekt-ID ein',
      addGoogleServiceAccountKey:
        'Dienstkonten-Schlüssel (lassen Sie es leer, wenn Sie die Anwendungsbasierte Standardauthentifizierung verwenden)',
      GoogleServiceAccountKeyMessage:
        'Bitte geben Sie den Google-Cloud-Dienstkonten-Schlüssel in Base64-Format ein',
      addGoogleRegion: 'Google-Cloud-Region',
      GoogleRegionMessage: 'Bitte geben Sie Ihre Google-Cloud-Region ein',
      modelProvidersWarn: `Bitte fügen Sie sowohl das Embedding-Modell als auch das LLM in <b>Settings > Modell-Anbieter</b> zuerst hinzu. Dann setzen Sie sie in 'System-Modell-Einstellungen'.`,
      apiVersion: 'Api-Version',
      apiVersionMessage: 'Bitte geben Sie Ihre Api-Version ein',
      add: 'Hinzufügen',
      updateDate: 'Update-Datum',
      role: 'Rolle',
      invite: 'Einladen',
      agree: 'Zustimmen',
      refuse: 'Ablehnen',
      teamMembers: 'Teammitglieder',
      joinedTeams: 'Beigetretenen Teams',
    },
    message: {
      registered: 'Registriert!',
      logout: 'Abmelden',
      logged: 'Angemeldet!',
      pleaseSelectChunk: 'Bitte wählen Sie Chunk!',
      modified: 'Geändert',
      created: 'Erstellt',
      deleted: 'Gelöscht',
      renamed: 'Umbenannt',
      operated: 'Bearbeitet',
      updated: 'Aktualisiert',
      uploaded: 'Hochgeladen',
      200: 'Der Server hat die angeforderte Daten erfolgreich zurückgegeben.',
      201: 'Erstellen oder Daten ändern erfolgreich.',
      202: 'Eine Anfrage wurde im Hintergrund in der Warteschlange (asynchrone Aufgabe) gestellt.',
      204: 'Daten gelöscht.',
      400: 'Es gab einen Fehler in der Anfrage, und der Server hat keine Daten erstellt oder geändert.',
      401: 'Der Benutzer hat keine Berechtigungen (falscher Token, Benutzername, Passwort).',
      403: 'Der Benutzer ist autorisiert, aber der Zugriff ist verboten.',
      404: 'Die Anfrage wurde für ein nicht existierendes Dokument gestellt, und der Server hat die Operation nicht ausgeführt.',
      406: 'Die angeforderte Formatierung ist nicht verfügbar.',
      410: 'Das angeforderte Ressourcen ist dauerhaft gelöscht und wird nicht wieder verfügbar sein.',
      413: 'Die Gesamtgröße der hochgeladenen Dateien ist zu groß.',
      422: 'Bei der Erstellung eines Objekts trat ein Validierungsfehler auf.',
      500: 'Ein Serverfehler trat auf, bitte überprüfen Sie den Server.',
      502: 'Gateway-Fehler.',
      503: 'Die Dienste sind nicht verfügbar und der Server ist vorübergehend überlastet oder wird gewartet.',
      504: 'Gateway-Zeitüberschreitung.',
      requestError: 'Anfragefehler',
      networkAnomalyDescription:
        'Es gibt eine Abnormität in Ihrem Netzwerk und Sie können nicht auf den Server zugreifen.',
      networkAnomaly: 'Netzwerkanomalie',
      hint: 'Hinweis',
    },
    fileManager: {
      name: 'Name',
      uploadDate: 'Upload-Datum',
      knowledgeBase: 'Wissensbasis',
      size: 'Größe',
      action: 'Aktion',
      addToKnowledge: 'Zu Wissensbasis hinzufügen',
      pleaseSelect: 'Bitte wählen Sie',
      newFolder: 'Neuer Ordner',
      file: 'Datei',
      uploadFile: 'Datei hochladen',
      directory: 'Verzeichnis',
      uploadTitle: 'Klicken oder Datei hierhin ziehen, um hochzuladen',
      uploadDescription:
        'Unterstützt Einzel- oder Massenupload. Der Upload von Unternehmensdaten oder anderen gesperrten Dateien ist strengstens verboten.',
      local: 'Lokale Dateien',
      s3: 'S3-Dateien',
      preview: 'Vorschau',
      fileError: 'Dateifehler',
      uploadLimit:
        'Die Dateigröße darf nicht größer als 10M sein, und die Gesamtzahl der Dateien darf nicht größer als 128 sein',
      destinationFolder: 'Zielverzeichnis',
    },
    flow: {
      cite: 'Zitieren',
      citeTip: 'Zitieren',
      name: 'Name',
      nameMessage: 'Bitte geben Sie einen Namen ein',
      description: 'Beschreibung',
      examples: 'Beispiele',
      to: 'Zu',
      msg: 'Nachrichten',
      messagePlaceholder: 'Nachricht',
      messageMsg: 'Bitte geben Sie eine Nachricht oder löschen Sie diese Feld.',
      addField: 'Feld hinzufügen',
      addMessage: 'Nachricht hinzufügen',
      loop: 'Schleife',
      loopTip:
        'Schleifen ist der obere Grenzwert der Anzahl der Schleifen des aktuellen Komponenten, wenn die Anzahl der Schleifen die Schleifen-Grenze überschreitet, bedeutet dies, dass der Komponente die aktuelle Aufgabe nicht abschließen kann, bitte optimieren Sie den Agenten erneut',
      yes: 'Ja',
      no: 'Nein',
      key: 'Schlüssel',
      componentId: 'Komponenten-ID',
      add: 'Hinzufügen',
      operation: 'Operation',
      run: 'Ausführen',
      save: 'Speichern',
      title: 'ID:',
      beginDescription: 'Hier beginnt der Fluss.',
      answerDescription: `Ein Komponente, die als Schnittstelle zwischen Mensch und Bot dient, Benutzer-Eingaben empfängt und die Antworten des Agenten anzeigt.`,
      retrievalDescription: `Ein Komponente, der Informationen aus einer bestimmten Wissensbasis abruft und 'Leere Antwort' zurückgibt, wenn keine Informationen gefunden werden. Stellen Sie sicher, dass die richtige Wissensbasis ausgewählt ist.`,
      generateDescription: `Ein Komponente, der den LLM auffordert, Antworten zu generieren. Stellen Sie sicher, dass der Prompt korrekt eingerichtet ist.`,
      categorizeDescription: `Ein Komponente, der den LLM verwendet, um Benutzer-Eingaben in vordefinierte Kategorien einzuteilen. Stellen Sie sicher, dass Sie den Namen, die Beschreibung und Beispiele für jede Kategorie sowie die entsprechende nächste Komponente angeben.`,
      relevantDescription: `Ein Komponente, der den LLM verwendet, um zu beurteilen, ob die obere Ebene der Ausgabe relevant zur Benutzer-Eingabe ist. Stellen Sie sicher, dass Sie die nächste Komponente für jeden Urteilsergebnis angeben.`,
      rewriteQuestionDescription: `Ein Komponente, der eine Benutzer-Eingabe feiner präzisiert, wenn sie nicht relevant ist. Es wiederholt dies, bis die vordefinierte Schleifen-Grenze erreicht ist. Stellen Sie sicher, dass die obere Ebene ist 'Relevant' und die untere Ebene ist 'Abfrage'. `,
      messageDescription:
        "Ein Komponente, der eine statische Nachricht sendet. Wenn mehrere Nachrichten bereitgestellt werden, wird eine zufällig ausgewählt und gesendet. Stellen Sie sicher, dass die untere Ebene ist 'Antwort', der Schnittstellenkomponente.",
      keywordDescription: `Ein Komponente, der die Top-N Suchergebnisse aus der Benutzer-Eingabe abruft. Stellen Sie sicher, dass die Top-N-Wert korrekt eingerichtet ist, bevor Sie ihn verwenden.`,
      switchDescription: `Ein Komponente, der auf der Grundlage der Ausgabe der vorherigen Komponenten Bedingungen auswertet und die Ausführung des Flusses entsprechend lenkt. Es ermöglicht komplexe Logik durch Definition von Fällen und Angabe von Aktionen für jeden Fall oder Standardaktion, wenn keine Bedingungen erfüllt sind.`,
      wikipediaDescription: `Dieser Komponente wird verwendet, um Suchergebnisse von wikipedia.org abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.`,
      promptText: `Zusammenfassen Sie bitte die folgenden Absätze. Seien Sie vorsichtig mit Zahlen, machen Sie nichts auf, was nicht stimmt. Absätze wie folgt:
        {input}
  Die oben stehende ist der Inhalt, den Sie zusammenfassen müssen.`,
      createGraph: 'Agent erstellen',
      createFromTemplates: 'Von Vorlagen erstellen',
      retrieval: 'Abfrage',
      generate: 'Generieren',
      answer: 'Interagieren',
      categorize: 'Kategorisieren',
      relevant: 'Relevant',
      rewriteQuestion: 'Umbuchen',
      rewrite: 'Umbuchen',
      begin: 'Beginnen',
      message: 'Nachricht',
      blank: 'Leer',
      createFromNothing: 'Erstellen Sie Ihren Agenten von vorne',
      addItem: 'Artikel hinzufügen',
      addSubItem: 'Unterartikel hinzufügen',
      nameRequiredMsg: 'Name ist erforderlich',
      nameRepeatedMsg: 'Der Name darf nicht wiederholt werden',
      keywordExtract: 'Schlüsselwort',
      keywordExtractDescription: `Ein Komponente, der Schlüsselwörter aus Benutzer-Eingaben extrahiert, wobei Top N die Anzahl der zu extrahierenden Schlüsselwörter bestimmt.`,
      baidu: 'Baidu',
      baiduDescription: `Dieser Komponente wird verwendet, um Suchergebnisse von www.baidu.com abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Ein Komponente, der Suchergebnisse von duckduckgo.com abruft, wobei TopN die Anzahl der Suchergebnisse bestimmt. Es ergänzt bestehende Wissensbasen.',
      channel: 'Kanal',
      channelTip: `Textsuche oder Nachrichten such auf der Komponente-Eingabe durchführen`,
      text: 'Text',
      news: 'Nachrichten',
      messageHistoryWindowSize: 'Nachrichtenfenstergröße',
      messageHistoryWindowSizeTip:
        'Die Fenstergröße der Konversationsgeschichte, die vom LLM gesehen werden muss. Je größer, desto besser. Achten Sie jedoch auf die maximale Inhaltslänge des LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      pubMedDescription:
        'Dieser Komponente wird verwendet, um Suchergebnisse von https://pubmed.ncbi.nlm.nih.gov/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen. E-Mail ist ein erforderliches Feld.',
      email: 'E-Mail',
      emailTip:
        'Dieser Komponente wird verwendet, um Suchergebnisse von https://pubmed.ncbi.nlm.nih.gov/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen. E-Mail ist ein erforderliches Feld.',
      arXiv: 'ArXiv',
      arXivDescription:
        'Dieser Komponente wird verwendet, um Suchergebnisse von https://arxiv.org/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.',
      sortBy: 'Sortieren nach',
      submittedDate: 'Eingereichtes Datum',
      lastUpdatedDate: 'Zuletzt aktualisiertes Datum',
      relevance: 'Relevanz',
      google: 'Google',
      googleDescription:
        'Dieser Komponente wird verwendet, um Suchergebnisse vonhttps://www.google.com/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N und SerpApi-API-Schlüssel legen die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.',
      bing: 'Bing',
      bingDescription:
        'Dieser Komponente wird verwendet, um Suchergebnisse von https://www.bing.com/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N und Bing-Abonnement-Schlüssel legen die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.',
      apiKey: 'Api-Schlüssel',
      country: 'Land',
      language: 'Sprache',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Dieser Komponente wird verwendet, um Suchergebnisse von https://scholar.google.com/ abzurufen. Typischerweise wird es als Ergänzung zu Wissensbasen verwendet. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.',
      yearLow: 'Jahr tief',
      yearHigh: 'Jahr hoch',
      patents: 'Patente',
      data: 'Daten',
      deepL: 'DeepL',
      deepLDescription:
        'Dieser Komponente wird verwendet, um Übersetzungen von https://www.deepl.com/ abzurufen. Typischerweise wird es eine spezialisierte Übersetzungsresultat liefern.',
      authKey: 'Auth-Schlüssel',
      sourceLang: 'Quellensprache',
      targetLang: 'Ziel-Sprache',
      gitHub: 'GitHub',
      githubDescription:
        'Dieser Komponente wird verwendet, um Repository von https://github.com/ abzurufen. Top N legt die Anzahl der Suchergebnisse fest, die Sie anpassen müssen.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Dieser Komponente wird verwendet, um Übersetzungen von https://fanyi.baidu.com/ abzurufen. Typischerweise wird es eine spezialisierte Übersetzungsresultat liefern.',
      appid: 'App-ID',
      secretKey: 'Geheimer Schlüssel',
      domain: 'Domain',
      transType: 'Übersetzungs-Typ',
      baiduSecretKeyOptions: {
        translate: 'Allgemeine Übersetzung',
        fieldtranslate: 'Feld-Übersetzung',
      },
      baiduDomainOptions: {
        it: 'Informationstechnologie',
        finance: 'Finanzen und Wirtschaft',
        machinery: 'Maschinenbau',
        senimed: 'Biomedizin',
        novel: 'Online-Literatur',
        academic: 'Akademische Arbeit',
        aerospace: 'Luft- und Raumfahrt',
        wiki: 'Geistes- und Sozialwissenschaften',
        news: 'Nachrichten und Informationen',
        law: 'Gesetze und Vorschriften',
        contract: 'Vertrag',
      },
      baiduSourceLangOptions: {
        auto: 'Automatisch erkennen',
        zh: 'Chinesisch',
        en: 'Englisch',
        yue: 'Kantonesisch',
        wyw: 'Klassisches Chinesisch',
        jp: 'Japanisch',
        kor: 'Koreanisch',
        fra: 'Französisch',
        spa: 'Spanisch',
        th: 'Thai',
        ara: 'Arabisch',
        ru: 'Russisch',
        pt: 'Portugiesisch',
        de: 'Deutsch',
        it: 'Italienisch',
        el: 'Griechisch',
        nl: 'Niederländisch',
        pl: 'Polnisch',
        bul: 'Bulgarisch',
        est: 'Estnisch',
        dan: 'Dänisch',
        fin: 'Finnisch',
        cs: 'Tschechisch',
        rom: 'Rumänisch',
        slo: 'Slowenisch',
        swe: 'Schwedisch',
        hu: 'Ungarisch',
        cht: 'Traditionelles Chinesisch',
        vie: 'Vietnamesisch',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Dieser Komponente wird verwendet, um Wetterbezogene Informationen von https://www.qweather.com/ abzurufen. Sie können Wetter, Indizes, Luftqualität erhalten.',
      lang: 'Sprache',
      type: 'Typ',
      webApiKey: 'Web-Api-Schlüssel',
      userType: 'Benutzer-Typ',
      timePeriod: 'Zeitraum',
      qWeatherLangOptions: {
        zh: 'Simplified Chinese',
        'zh-hant': 'Traditional Chinese',
        en: 'English',
        de: 'German',
        es: 'Spanish',
        fr: 'French',
        it: 'Italian',
        ja: 'Japanese',
        ko: 'Korean',
        ru: 'Russian',
        hi: 'Hindi',
        th: 'Thai',
        ar: 'Arabic',
        pt: 'Portuguese',
        bn: 'Bengali',
        ms: 'Malay',
        nl: 'Dutch',
        el: 'Greek',
        la: 'Latin',
        sv: 'Swedish',
        id: 'Indonesian',
        pl: 'Polish',
        tr: 'Turkish',
        cs: 'Czech',
        et: 'Estonian',
        vi: 'Vietnamese',
        fil: 'Filipino',
        fi: 'Finnish',
        he: 'Hebrew',
        is: 'Icelandic',
        nb: 'Norwegian',
      },
      qWeatherTypeOptions: {
        weather: 'Wettervorhersage',
        indices: 'Wetterlebensindex',
        airquality: 'Luftqualität',
      },
      qWeatherUserTypeOptions: {
        free: 'Frei',
        paid: 'Bezahlt',
      },
      qWeatherTimePeriodOptions: {
        now: 'Jetzt',
        '3d': '3 Tage',
        '7d': '7 Tage',
        '10d': '10 Tage',
        '15d': '12 Tage',
        '30d': '30 Tage',
      },
      publish: 'Veröffentlichen',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Der Komponente wird verwendet, um Ergebnisse aus der entsprechenden relationalen Datenbank über SQL-Anweisungen abzurufen. Unterstützt MySQL, PostgreSQL, MariaDB.',
      dbType: 'Datenbanktyp',
      database: 'Datenbank',
      username: 'Benutzername',
      host: 'Host',
      port: 'Port',
      password: 'Passwort',
      switch: 'Schalter',
      logicalOperator: 'Logischer Operator',
      switchOperatorOptions: {
        equal: 'Gleich',
        notEqual: 'ungleich',
        gt: 'Größer als',
        ge: 'Größer gleich',
        lt: 'Kleiner als',
        le: 'Kleiner gleich',
        contains: 'Enthält',
        notContains: 'Enthält nicht',
        startWith: 'Beginnt mit',
        endWith: 'Endet mit',
        empty: 'Leer',
        notEmpty: 'Nicht leer',
      },
      switchLogicOperatorOptions: {
        and: 'Und',
        or: 'Oder',
      },
      operator: 'Operator',
      value: 'Wert',
      useTemplate: 'Vorlage verwenden',
      wenCai: 'WenCai',
      queryType: 'Abfrage-Typ',
      wenCaiDescription:
        'Der Komponente kann verwendet werden, um Informationen zu einem breiten Bereich finanzieller Bereiche zu erhalten, einschließlich aber nicht beschränkt auf Aktien, Fonds usw...',
      wenCaiQueryTypeOptions: {
        stock: 'Aktien',
        zhishu: 'Index',
        fund: 'Fonds',
        hkstock: 'Hongkong-Aktien',
        usstock: 'US-Aktienmarkt',
        threeboard: 'Neuer OTC-Markt',
        conbond: 'Umwandlungsanleihe',
        insurance: 'Versicherung',
        futures: 'Termine',
        lccp: 'Finanzierung',
        foreign_exchange: 'Devisen',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Dieser Komponente kann verwendet werden, um Nachrichteninformationen für die entsprechende Aktie von der Eastmoney-Website abzurufen.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Der Komponente wird verwendet, um Informationen über das Unternehmen auf der Grundlage des bereitgestellten Aktienzeichens abzurufen.',
      crawler: 'Web-Crawler',
      crawlerDescription:
        'Dieser Komponente kann verwendet werden, um HTML-Quellcode von einer bestimmten URL abzurufen.',
      proxy: 'Proxy',
      crawlerResultOptions: {
        html: 'HTML',
        markdown: 'Markdown',
        content: 'Inhalt',
      },
      extractType: 'Typ',
      info: 'Info',
      history: 'Geschichte',
      financials: 'Finanzinformationen',
      balanceSheet: 'Bilanz',
      cashFlowStatement: 'Kassenfluss',
      jin10: 'Jin10',
      jin10Description:
        'Dieser Komponente kann verwendet werden, um Informationen im Finanzsektor von der Jin10-Open-Plattform abzurufen, einschließlich schneller Nachrichten, Kalender, Zitate, Referenzen.',
      flashType: 'Typ',
      filter: 'Filter',
      contain: 'Enthält',
      calendarType: 'Kalendertyp',
      calendarDatashape: 'Kalendardatensatz',
      symbolsDatatype: 'Symbol-Typ',
      symbolsType: 'Symbol-Typ',
      jin10TypeOptions: {
        flash: 'Schnelle Nachrichten',
        calendar: 'Kalender',
        symbols: 'Zitate',
        news: 'Referenzen',
      },
      jin10FlashTypeOptions: {
        '1': 'Marktnachrichten',
        '2': 'Termine Nachrichten',
        '3': 'US-Hongkong Nachrichten',
        '4': 'A-Aktien Nachrichten',
        '5': 'Commodities & Forex Nachrichten',
      },
      jin10CalendarTypeOptions: {
        cj: 'Makroökonomische Datenkalender',
        qh: 'Termine Kalender',
        hk: 'Hongkong Aktienmarkt Kalender',
        us: 'US Aktienmarkt Kalender',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Daten',
        event: 'Event',
        holiday: 'Feiertag',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Commodity-Zitate',
        FOREX: 'Devisenzitate',
        FUTURE: 'Internationaler Markt-Zitate',
        CRYPTO: 'Kryptowährung-Zitate',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Commodity-Liste',
        quotes: 'Aktuelle Markt-Zitate',
      },
      concentrator: 'Konzentrator',
      concentratorDescription:
        'Ein Komponente, der die Ausgabe der obigen Komponente empfängt und als Eingabe an die unteren Komponenten weitergibt.',
      tuShare: 'TuShare',
      tuShareDescription:
        'Dieser Komponente kann verwendet werden, um finanzielle Nachrichten von Mainstream-Finanzseiten abzurufen, um Industrie- und quantitative Forschung zu unterstützen.',
      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Geradlinige Flush',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },
      token: 'Token',
      src: 'Quelle',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      keyword: 'Schlüsselwort',
      note: 'Hinweis',
      noteDescription: 'Hinweis',
      notePlaceholder: 'Bitte geben Sie einen Hinweis ein',
      invoke: 'Aufrufen',
      invokeDescription:
        'Dieser Komponente kann verwendet werden, um Remote-Endpunkt-Aufrufe auszuführen. Die Ausgabe anderer Komponenten kann als Parameter oder als konstante Parameter verwendet werden.',
      url: 'URL',
      method: 'Methode',
      timeout: 'Zeitüberschreitung',
      headers: 'Kopfzeilen',
      cleanHtml: 'HTML saubermachen',
      cleanHtmlTip:
        'Wenn die Antwort HTML-formatiert ist und nur der primäre Inhalt gewünscht wird, aktivieren Sie es.',
      reference: 'Referenz',
      input: 'Eingabe',
      output: 'Ausgabe',
      parameter: 'Parameter',
      howUseId: 'Wie verwendet man Agenten-ID?',
      content: 'Inhalt',
      operationResults: 'Operationsergebnisse',
    },
    footer: {
      profile: 'Alle Rechte vorbehalten @ React',
    },
    layout: {
      file: 'Datei',
      knowledge: 'Wissensbasis',
      chat: 'Chat',
    },
  },
};
